.footer-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: rgba(248, 184, 214, 0.754);
  width: 100%;
  min-width: 430px;
}

.ig-logo{
  width: 65px;
  height: 50px;
}

.tiktok-logo{
  width: 65px;
  margin-left: 8px;
}