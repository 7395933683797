.img-container{
  height: 100%;
  min-height: 100vh;
  max-width: 1300px;
  min-width: 430px;
  margin: 0 auto;
  background-color: rgba(248, 199, 222, 0.8); 

}

.img-pics{
  width: 300px;
  height: 300px;
  padding: 5px;
}

.img-flex-container{
  width: 75%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  padding-bottom: 50px;
}

.gallery-span{
display: flex;
justify-content: center;
font-size: 5rem;
margin-bottom: 30px;
color: #fff;
padding-top: 30px;
}


@media screen and (max-width: 430px) {
  .img-pics{
  width: 200px;
  height: 170px;
  padding: 5px;
}
.img-flex-container{
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.gallery-span{
  font-size: 4rem;
}
}