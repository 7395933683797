.home-container{
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  min-width: 430px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(248, 199, 222, 0.8); 
  padding-left: 25px;
  padding-right: 25px;
}

hr{
  width: 95%;
  margin:  0 auto;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #000000;
  padding: 0;
  color: black;
}

.logo{
  max-width: 200px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 50%;
}

.greetings-span{
  color: #202020b1;
  text-align: center;
}

.home-pics-div{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  align-content: flex-start;
}

.home-pics{
  width: 400px;
  min-width: 50px;
  height: 350px;
  padding: 10px;
}


@media screen and (max-width: 430px) {
  .home-pics{
  width: 200px;
  height: 175px;
  padding: 5px;
}
.home-pics-div{
  padding-left: 5px;
  padding-right: 5px;
}
.home-container{
  padding-left: 0px;
  padding-right: 0px;
}
}