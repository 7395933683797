.cookie-form{
  display: flex;
  flex-direction: column;
  box-shadow:  0 1px 5px 0 rgba(0, 0, 0, .9);
  max-width: 600px;
  min-width: 400px;
  margin: 0 auto;
  border-radius: 2px;
  min-height: 900px;
  padding: 40px;
  margin-top: 60px;
  margin-bottom: 60px
}

span{
  margin-bottom: 20px;
}

fieldset{
  padding: 5px;
}

.user-data{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.text{
  height: 25px;
}

h1{
  font-family: Times New Roman, Times, serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.files{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

small{
  color: #6c757d;
  margin-bottom: 10px;
}

#date{
  width: 150px;
}

#servings{
  width: 50px;
}

#file{
  display: none;
}

.file-label{
  width: 50px;
  height: 70px;
  cursor: pointer;
  margin-right: 30px;
}

.file-flexdiv{
  display: flex;
}

.file-list{
  display: flex;
  flex-direction: column;
  margin: 0px;
  overflow-y: scroll;
  height: 60px;
  font-size: x-small;
}

button{
  cursor: pointer;
  width: 35%;
  margin: 0 auto;
  background-color: #fff;
}

.amount-text{
  margin-left: 5px;
}

.address-text{
  margin-left: 5px;
}

@media screen and (max-width: 600px) {
  .cookie-form{
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}