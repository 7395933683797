.nav{
  display: flex;
  justify-content: space-around;
  align-items: end;
  height:70px;
  min-width: 430px;
  background-color: rgba(248, 184, 214, 0.754);
  padding-bottom: 5px;
}

.name{
  font-size: 2rem;
  text-decoration: none;
 color:  #fff;
}
.home-link{
  text-decoration: none;
 color:  #fff;
}

.cake-gallery-link{
  text-decoration: none;
 color:  #fff;
}

.cookie-gallery-link{
  text-decoration: none;
 color:  #fff;
}

.form-link{
  text-decoration: none;
 color:  #fff;
}

.home-link:hover{
  text-decoration: underline;
}

.cake-gallery-link:hover{
  text-decoration: underline;
}

.cookie-gallery-link:hover{
  text-decoration: underline;
}

.form-link:hover{
  text-decoration: underline;
}



@media screen and (max-width: 988px) {
  .nav{
    display: none;
  }
  
.topnav {
  overflow: hidden;
  position: relative;
  background-color: rgba(248, 184, 214, 0.754);
  min-width: 430px;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  background: rgb(236, 140, 190);
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  min-height: 64px;
  width: 60px;
}

.active {
  background-color: rgba(248, 184, 214, 0.754);
  color: white;
  min-width: 430px;
}

.nav-name{
  font-size: 30px;
}

.fa{
  margin-top: 9px;
  margin-left: 5px;
}
}

@media screen and (min-width: 988px) {
.topnav{
  display: none;
}
}