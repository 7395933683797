.form{
  display: flex;
  flex-direction: column;
  box-shadow:  0 1px 5px 0 rgba(0, 0, 0, .9);
  max-width: 600px;
  min-width: 400px;
  margin: 0 auto;
  border-radius: 2px;
  min-height: 1000px;
  padding: 40px;
  margin-top: 60px;
  margin-bottom: 60px
}

span{
  margin-bottom: 20px;
}

fieldset{
  padding: 5px;
}

.user-data{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.text{
  height: 25px;
}

h1{
  font-family: Times New Roman, Times, serif;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.files{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

small{
  color: #6c757d;
  margin-bottom: 10px;
}

#date{
  width: 150px;
}

#servings{
  width: 50px;
}

#file{
  display: none;
}

.file-label{
  width: 50px;
  height: 70px;
  cursor: pointer;
  margin-right: 30px;
}

.file-flexdiv{
  display: flex;
}

.file-list{
  display: flex;
  flex-direction: column;
  margin: 0px;
  overflow-y: scroll;
  height: 60px;
  font-size: x-small;
}

.submit-btn{
  margin: 0px 33%;
  width: 220px;
}

.btn{
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border: solid grey 2px;
  font-size: 20px;
  margin-right: 20px;
}

.btn:hover{
  opacity: .5;
}

.spinner-div{
  margin: 0 auto;
}

.form-success-resp{
  color: green;
  font-size: 15px;
  text-align: center;
}

.form-msg{
  display: flex;
  flex-direction: column;
}

.form-msg p {
  text-align: center;
}

.check-mark{
  display: flex;
  gap: 10px;
  margin: 0 auto;
  width: 320px;
  color: green;
  font-size: 30px;
  display: none;
}

.fa-check-circle{
  font-size: 50px;
  color: green;
}

.error-msg{
  display: none;
  color: red;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .form{
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px; 
    margin-right: 10px; 
  }
}